import React from "react"
import { Layout } from "@components/layout"
import { wrapper } from "./styles/contact.module.scss"
import Form from "./Form"

const Contact = ({ pageContext }) => {
  const { page } = pageContext

  return (
    <Layout {...page}>
      <div className={wrapper}>
        <h1>Masz pytania? </h1>
        <h1>Napisz do nas!</h1>
        <Form />
      </div>
    </Layout>
  )
}

export default Contact
